import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Wrapper = styled.div`
  background-color: black;
  height: 100%;
  padding-top: 300px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 65px;
`

const H1 = styled.h1`
  font-size: 2rem;
  font-weight: bold;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="Oops!" />
      <Wrapper>
        <H1>Oops! <br/> You just hit a route that doesn&#39;t exist... the sadness.</H1>
      </Wrapper>
  </Layout>
)

export default NotFoundPage
